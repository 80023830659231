import React, { useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

function Contact() {
  const data = {
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  };

  const [state, setstate] = useState(data);
  const [send, setSend] = useState(false);

  const handleChange = (e) => {
    setstate({ ...state, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://eob6urpxb7glv9i.m.pipedream.net");
      xhr.send(JSON.stringify({ data: state }));

      setstate(data);
    } catch (e) {
      console.error(e);
    }
  };

  let btn = !send ? (
    <button type="submit" className="submit-btn btn">
      Envoyer
    </button>
  ) : (
    <button
      type="submit"
      className="submit-btn btn"
      style={{ backgroundColor: "green" }}
      disabled
    >
      le message a bien été envoyer
    </button>
  );
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <section className="contact-page">
          <article className="contact-form">
            <h3>Parlons de votre projet autour d’un café</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  onChange={handleChange}
                  id="name"
                  type="text"
                  placeholder="Nom"
                  name="name"
                  value={state.name}
                  className="form-control"
                  required
                />
                <input
                  type="text"
                  id="company"
                  onChange={handleChange}
                  placeholder="Entreprise"
                  value={state.company}
                  name="company"
                  className="form-control"
                />
                <input
                  type="email"
                  onChange={handleChange}
                  placeholder="Email "
                  id="email"
                  value={state.email}
                  name="email"
                  className="form-control"
                />
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Télephone"
                  value={state.phone}
                  id="phone"
                  name="phone"
                  className="form-control"
                />

                <textarea
                  name="message"
                  id="message"
                  value={state.message}
                  onChange={handleChange}
                  rows="10"
                  placeholder="Message"
                  className="form-control"
                ></textarea>
                {btn}
              </div>
            </form>
          </article>
        </section>
      </Layout>
    </>
  );
}

export default Contact;
